import {
  Draft,
  PayloadAction,
  createSelector,
  createSlice
} from '@reduxjs/toolkit'
import { RootState } from '../../App.store'
import {
  DraftEntryResponseOption,
  DraftMatrixRow,
  ForkTag,
  TextEntryState
} from '../../data/gql-gen/questionnaire/graphql'
import {
  DraftLogicClauseProposition,
  DraftQuestionnaire,
  DraftQuestionnaireEntry,
  EntrySettingValue,
  MediaType,
  PositionTextSelection,
  QuestionSettingCode,
  SettingValue,
  SliderNumbers,
  ValidationError
} from '../../data/model/questionnaire'
import {
  replacingTextAt,
  splitTextIntoLinesAndCleanup
} from '../../hooks/copyPaste/utils'
import {
  createDraftMatrixRow,
  createDraftResponseOption,
  ingestQuestionnaireStateFromApollo
} from './Questionnaire.utils'
import {
  hasErrors,
  updateResponsesValidationErrorsState,
  updateRowsValidationErrorsState,
  validateSliderNumbers
} from './QuestionnaireValidation.utils'

export type ResponseOptionsByQuestion = Record<
  string,
  DraftEntryResponseOption[]
>

export type SettingsByQuestion = Record<string, EntrySettingValue[]>
export type QuestionLogicByQuestion = Record<
  string,
  DraftLogicClauseProposition[][]
>

export type MatrixRowsByQuestion = Record<string, DraftMatrixRow[]>

export interface QuestionnaireState {
  responseOptionsByQuestion: ResponseOptionsByQuestion
  previousResponseOptionsByQuestion: ResponseOptionsByQuestion
  matrixRowsByQuestion: MatrixRowsByQuestion
  settingsByQuestion: SettingsByQuestion
  validationErrorsByQuestion: Record<string, ValidationError>
  questionLogicByQuestion: Record<string, DraftLogicClauseProposition[][]>
}

const initialState: QuestionnaireState = {
  responseOptionsByQuestion: {},
  previousResponseOptionsByQuestion: {},
  matrixRowsByQuestion: {},
  settingsByQuestion: {},
  validationErrorsByQuestion: {},
  questionLogicByQuestion: {}
}

const updateResponseOptionAtPosition = (
  state: Draft<QuestionnaireState>,
  questionLk: string,
  position: number,
  responseOption: DraftEntryResponseOption
) => {
  const ro = state.responseOptionsByQuestion[questionLk][position]
  Object.assign(ro, responseOption)
  updateResponsesValidationErrorsState(questionLk, state)
}

const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState,
  reducers: {
    questionnaireLoaded(state, { payload }: PayloadAction<DraftQuestionnaire>) {
      ingestQuestionnaireStateFromApollo(state, payload)
    },
    questionCardEmptied(
      state,
      { payload }: PayloadAction<{ questionId: string }>
    ) {
      const rows = state.matrixRowsByQuestion[payload.questionId]
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      rows?.forEach((row) => {
        if (row.question) {
          row.question.text = ''
        }
      })
      const responses = state.responseOptionsByQuestion[payload.questionId]
      responses.forEach((row) => {
        if (row.responseOption) {
          row.responseOption.value = ''
        }
      })
      updateResponsesValidationErrorsState(payload.questionId, state)
      updateRowsValidationErrorsState(payload.questionId, state)
    },
    responseOptionUpdated(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        responseOptionLk: string
        value: string
      }>
    ) {
      const { questionLk, responseOptionLk, value } = payload
      const responseOption = state.responseOptionsByQuestion[questionLk].find(
        (ro) => ro.responseOptionLk === responseOptionLk
      )?.responseOption

      if (responseOption) {
        responseOption.value = value

        updateResponsesValidationErrorsState(questionLk, state)
      }
    },
    responseOptionsReoredered(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        destinationIndex: number
        sourceIndex: number
      }>
    ) {
      const { questionLk, sourceIndex, destinationIndex } = payload
      const ros = state.responseOptionsByQuestion[questionLk]
      const [ro] = ros.splice(sourceIndex, 1)
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (ro) {
        ros.splice(destinationIndex, 0, ro)
        ros.forEach((ro, i) => {
          ro.position = i
        })
      }
    },
    matrixRowReordered(
      state,
      {
        payload
      }: PayloadAction<{
        matrixTitleLk: string
        destinationIndex: number
        sourceIndex: number
      }>
    ) {
      const { matrixTitleLk, sourceIndex, destinationIndex } = payload
      const rows = state.matrixRowsByQuestion[matrixTitleLk]
      const [row] = rows.splice(sourceIndex, 1)
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (row) {
        rows.splice(destinationIndex, 0, row)
        rows.forEach((row, i) => {
          row.position = i
        })
      }
    },
    responseOptionAdded(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        position: number
      }>
    ) {
      const { questionLk, position } = payload
      const ros = state.responseOptionsByQuestion[questionLk]

      ros.splice(position, 0, createDraftResponseOption(position))

      ros.slice(position + 1).forEach((ro) => {
        ro.position += 1
      })
    },
    responseOptionCreated(
      state,
      {
        payload: { questionLk, position, responseOption }
      }: PayloadAction<{
        questionLk: string
        position: number
        responseOption: DraftEntryResponseOption
      }>
    ) {
      updateResponseOptionAtPosition(
        state,
        questionLk,
        position,
        responseOption
      )
    },
    responseOptionPasted(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        position: number
        responseOptionTexts: string[]
        positionSelectedText?: PositionTextSelection
      }>
    ) {
      const {
        questionLk,
        position,
        responseOptionTexts,
        positionSelectedText
      } = payload

      const ros = state.responseOptionsByQuestion[questionLk]
      const { responseOption } = ros[position]

      if (!responseOption) {
        return
      }

      if (
        positionSelectedText &&
        typeof positionSelectedText.startPositionSelectedText === 'number' &&
        typeof positionSelectedText.endPositionSelectedText === 'number'
      ) {
        const newResponseValue = replacingTextAt(
          responseOption.value,
          responseOptionTexts[0],
          positionSelectedText.startPositionSelectedText,
          positionSelectedText.endPositionSelectedText
        )
        responseOption.value = newResponseValue
        responseOptionTexts.slice(1).forEach((roText, i) => {
          const insertAtPosition = position + 1 + i
          const roToInsertAt = ros[insertAtPosition]?.responseOption

          if (insertAtPosition > ros.length - 1) {
            ros.push(createDraftResponseOption(insertAtPosition, roText))
          } else if (roToInsertAt?.value) {
            ros.splice(
              insertAtPosition,
              0,
              createDraftResponseOption(insertAtPosition, roText)
            )
            ros.slice(insertAtPosition + 1).forEach((shiftedRo) => {
              const newShiftedPosition = shiftedRo.position + 1
              shiftedRo.position = newShiftedPosition
            })
          } else if (roToInsertAt) {
            roToInsertAt.value = roText
          }
        })
      } else {
        // pasting was done from title
        responseOptionTexts.forEach((roText, i) => {
          const insertAtPosition = position + i
          const roToInsertAt = ros[insertAtPosition]?.responseOption
          if (insertAtPosition > ros.length - 1) {
            ros.push(createDraftResponseOption(insertAtPosition, roText))
          } else if (roToInsertAt?.value) {
            ros.splice(
              insertAtPosition,
              0,
              createDraftResponseOption(insertAtPosition, roText)
            )
            ros.slice(insertAtPosition).forEach((shiftedRo) => {
              const newShiftedPosition = shiftedRo.position + 1
              shiftedRo.position = newShiftedPosition
            })
          } else if (roToInsertAt) {
            roToInsertAt.value = roText
          }
        })
      }

      updateResponsesValidationErrorsState(questionLk, state)
    },
    matrixRowPasted(
      state,
      {
        payload
      }: PayloadAction<{
        matrixTitleLk: string
        position: number
        textToPaste: string
        positionSelectedText?: PositionTextSelection
      }>
    ) {
      const { matrixTitleLk, position, textToPaste, positionSelectedText } =
        payload
      const matrixRowTexts = splitTextIntoLinesAndCleanup(textToPaste)
      const rows = state.matrixRowsByQuestion[matrixTitleLk]
      const { question } = rows[position]

      const insertMatrixRow = ({
        index,
        rowText,
        ignoreFirst = false
      }: {
        index: number
        rowText: string
        ignoreFirst?: boolean
      }) => {
        const insertAtPosition = position + index + (ignoreFirst ? 1 : 0)
        const newMatrixRow = createDraftMatrixRow(insertAtPosition, rowText)
        const question = rows[insertAtPosition]?.question
        if (insertAtPosition > rows.length - 1) {
          rows.push(newMatrixRow)
        } else if (question?.text) {
          rows.splice(insertAtPosition, 0, newMatrixRow)
          rows
            .slice(insertAtPosition + (ignoreFirst ? 1 : 0))
            .forEach((shiftedRow) => {
              const newShiftedPosition = shiftedRow.position + 1
              shiftedRow.position = newShiftedPosition
            })
        } else if (question) {
          question.text = rowText
        }
      }

      if (
        positionSelectedText &&
        typeof positionSelectedText.startPositionSelectedText === 'number' &&
        typeof positionSelectedText.endPositionSelectedText === 'number' &&
        question
      ) {
        const newMatrixRowText = replacingTextAt(
          question.text,
          matrixRowTexts[0],
          positionSelectedText.startPositionSelectedText,
          positionSelectedText.endPositionSelectedText
        )
        question.text = newMatrixRowText
        matrixRowTexts.slice(1).forEach((rowText, index) => {
          insertMatrixRow({ index, rowText, ignoreFirst: true })
        })
      } else {
        // pasting was done from title
        matrixRowTexts.forEach((rowText, index) => {
          insertMatrixRow({ index, rowText })
        })
      }

      updateRowsValidationErrorsState(matrixTitleLk, state)
    },
    responseOptionPinToggled(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        responseOptionLk: string
        pinned: boolean
      }>
    ) {
      const { questionLk, responseOptionLk, pinned } = payload
      const ro = state.responseOptionsByQuestion[questionLk].find(
        (ro) => ro.responseOptionLk === responseOptionLk
      )
      if (ro) {
        ro.pinned = pinned
      }
    },
    matrixRowPinToggled(
      state,
      {
        payload
      }: PayloadAction<{
        matrixTitleLk: string
        questionLk: string
        pinned: boolean
      }>
    ) {
      const { questionLk, matrixTitleLk, pinned } = payload
      const row = state.matrixRowsByQuestion[matrixTitleLk].find(
        (ro) => ro.questionLk === questionLk
      )
      if (row) {
        row.pinned = pinned
      }
    },
    responseOptionExclusiveToggled(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        responseOptionLk: string
        exclusive: boolean
      }>
    ) {
      const { questionLk, responseOptionLk, exclusive } = payload
      const ro = state.responseOptionsByQuestion[questionLk].find(
        (ro) => ro.responseOptionLk === responseOptionLk
      )
      if (ro) {
        ro.exclusive = exclusive
      }
    },
    responseOptionTextEntryToggled(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        responseOptionLk: string
        textEntryState: TextEntryState
      }>
    ) {
      const { questionLk, responseOptionLk, textEntryState } = payload
      const ro = state.responseOptionsByQuestion[questionLk].find(
        (ro) => ro.responseOptionLk === responseOptionLk
      )
      if (ro) {
        ro.textEntryState = textEntryState
      }
    },
    maskingRulesSet(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        responseOptionLk: string
        maskingRules: DraftEntryResponseOption['maskingRules']
        flattenAllEntries: DraftQuestionnaireEntry[]
      }>
    ) {
      const { questionLk, responseOptionLk, maskingRules, flattenAllEntries } =
        payload
      const ro = state.responseOptionsByQuestion[questionLk].find(
        (ro) => ro.responseOptionLk === responseOptionLk
      )
      if (ro) {
        ro.maskingRules = maskingRules
      }
      updateResponsesValidationErrorsState(questionLk, state, flattenAllEntries)
    },
    rowMaskingRulesSet(
      state,
      {
        payload
      }: PayloadAction<{
        matrixTitleLk: string
        questionLk: string
        maskingRules: DraftEntryResponseOption['maskingRules']
        flattenAllEntries: DraftQuestionnaireEntry[]
      }>
    ) {
      const { questionLk, matrixTitleLk, maskingRules, flattenAllEntries } =
        payload
      const row = state.matrixRowsByQuestion[matrixTitleLk].find(
        (row) => row.questionLk === questionLk
      )
      if (row) {
        row.maskingRules = maskingRules
      }
      updateRowsValidationErrorsState(questionLk, state, flattenAllEntries)
    },
    rowForksSet(
      state,
      {
        payload
      }: PayloadAction<{
        matrixTitleLk: string
        questionLk: string
        forkTags: ForkTag[]
      }>
    ) {
      const { questionLk, matrixTitleLk, forkTags } = payload
      const row = state.matrixRowsByQuestion[matrixTitleLk].find(
        (row) => row.questionLk === questionLk
      )
      if (row) {
        row.forks = forkTags
      }
    },
    responseOptionRouteSet(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        responseOptionLk: string
        targetEntryNumber: number
        flattenAllEntries: DraftQuestionnaireEntry[]
      }>
    ) {
      const {
        questionLk,
        responseOptionLk,
        targetEntryNumber,
        flattenAllEntries
      } = payload
      const ro = state.responseOptionsByQuestion[questionLk].find(
        (ro) => ro.responseOptionLk === responseOptionLk
      )
      if (ro) {
        ro.route = {
          sinceDate: '',
          createdDate: '',
          targetNumber: targetEntryNumber,
          __typename: 'Route'
        }
      }
      updateResponsesValidationErrorsState(questionLk, state, flattenAllEntries)
    },
    responseOptionRouteUnset(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        responseOptionLk: string
        flattenAllEntries: DraftQuestionnaireEntry[]
      }>
    ) {
      const { questionLk, responseOptionLk, flattenAllEntries } = payload
      const ro = state.responseOptionsByQuestion[questionLk].find(
        (ro) => ro.responseOptionLk === responseOptionLk
      )
      if (ro) {
        ro.route = null
      }
      updateResponsesValidationErrorsState(questionLk, state, flattenAllEntries)
    },
    responseOptionImageSet(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        responseOptionLk: string
        mediaName: string
        mediaUrl: string
        renderedMediaUrl: string
      }>
    ) {
      const {
        questionLk,
        responseOptionLk,
        mediaName,
        mediaUrl,
        renderedMediaUrl
      } = payload
      const ro = state.responseOptionsByQuestion[questionLk].find(
        (ro) => ro.responseOptionLk === responseOptionLk
      )
      if (ro) {
        ro.media = {
          mediaName,
          mediaUrl,
          mediaType: MediaType.Image,
          renderedMediaUrl,
          __typename: 'ResponseOptionMedia'
        }
      }
    },
    responseOptionImageRemoved(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        responseOptionLk: string
      }>
    ) {
      const { questionLk, responseOptionLk } = payload
      const ro = state.responseOptionsByQuestion[questionLk].find(
        (ro) => ro.responseOptionLk === responseOptionLk
      )
      if (ro) {
        ro.media = null
      }
    },
    matrixRowImageSet(
      state,
      {
        payload
      }: PayloadAction<{
        matrixTitleLk: string
        questionLk: string
        mediaName: string
        mediaUrl: string
        renderedMediaUrl: string
      }>
    ) {
      const {
        matrixTitleLk,
        questionLk,
        mediaName,
        mediaUrl,
        renderedMediaUrl
      } = payload
      const row = state.matrixRowsByQuestion[matrixTitleLk].find(
        (row) => row.questionLk === questionLk
      )
      if (row) {
        row.questionMedia = {
          mediaName,
          mediaUrl,
          mediaType: MediaType.Image,
          createdDate: '',
          sinceDate: '',
          renderedMediaUrl,
          __typename: 'QuestionMedia'
        }
      }
    },
    matrixRowImageRemoved(
      state,
      {
        payload
      }: PayloadAction<{
        matrixTitleLk: string
        questionLk: string
      }>
    ) {
      const { matrixTitleLk, questionLk } = payload
      const row = state.matrixRowsByQuestion[matrixTitleLk].find(
        (row) => row.questionLk === questionLk
      )
      if (row) {
        row.questionMedia = null
      }
    },
    sliderNumbersSet(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        numbers: SliderNumbers
      }>
    ) {
      const { questionLk, numbers } = payload
      const sliderErrors = validateSliderNumbers(numbers)
      const currentError = state.validationErrorsByQuestion[questionLk]
      const newError = { ...currentError, sliderErrors }
      state.validationErrorsByQuestion[questionLk] = newError
      if (!hasErrors(newError)) {
        delete state.validationErrorsByQuestion[questionLk]
      }
    },
    setQuestionSetting(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        code: QuestionSettingCode
        value: SettingValue | string
      }>
    ) {
      const { questionLk, code, value } = payload
      const settings = state.settingsByQuestion[questionLk]
      // @todo Legacy eslint violation – fix this when editing
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (settings) {
        const newSettings = settings.filter((s) => s.code !== code)
        newSettings.push({
          code,
          value,
          createdDate: '',
          sinceDate: '',
          __typename: 'EntrySettingValue'
        })
        state.settingsByQuestion[questionLk] = newSettings
      } else {
        state.settingsByQuestion[questionLk] = [
          {
            code,
            value,
            createdDate: '',
            sinceDate: '',
            __typename: 'EntrySettingValue'
          }
        ]
      }
    },
    removeQuestionSetting(
      state,
      {
        payload
      }: PayloadAction<{
        questionLk: string
        settingCode: QuestionSettingCode
      }>
    ) {
      const { questionLk, settingCode } = payload
      state.settingsByQuestion[questionLk] = state.settingsByQuestion[
        questionLk
      ].filter(({ code }) => code !== settingCode)
    }
  }
})

/**
 * @deprecated Use the Apollo cache instead
 */
export const selectResponseOptionsByQuestion = (
  state: RootState
): ResponseOptionsByQuestion => {
  return state.questionnaire.responseOptionsByQuestion
}

const questionLkIdentity = (_: RootState, id: string) => id

/**
 * @deprecated Use the Apollo cache instead
 */
export const selectResponseOptionsByQuestionId = createSelector(
  [selectResponseOptionsByQuestion, questionLkIdentity],
  (responseOptionsByQuestion, id) => responseOptionsByQuestion[id]
)

/**
 * @deprecated Use the Apollo cache instead
 */
export const selectSettingsByQuestion = (
  state: RootState
): SettingsByQuestion => {
  return state.questionnaire.settingsByQuestion
}

/**
 * @deprecated Use the Apollo cache instead
 */
export const selectSettingsByQuestionId = createSelector(
  [selectSettingsByQuestion, questionLkIdentity],
  (settingsByQuestion, id) => settingsByQuestion[id]
)

/**
 * @deprecated Use the Apollo cache instead
 */
export const selectQuestionLogicByQuestion = (
  state: RootState
): QuestionLogicByQuestion => {
  return state.questionnaire.questionLogicByQuestion
}

/**
 * @deprecated Use the Apollo cache instead
 */
export const selectQuestionLogicByQuestionId = createSelector(
  [selectQuestionLogicByQuestion, questionLkIdentity],
  (questionLogicByQuestion, id) => questionLogicByQuestion[id]
)

/**
 * @deprecated Use the Apollo cache instead
 */
export const questionnaireLoaded =
  questionnaireSlice.actions.questionnaireLoaded

/**
 * @deprecated Use the Apollo cache instead
 */
export const questionCardEmptied =
  questionnaireSlice.actions.questionCardEmptied

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionUpdated =
  questionnaireSlice.actions.responseOptionUpdated

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionsReoredered =
  questionnaireSlice.actions.responseOptionsReoredered

/**
 * @deprecated Use the Apollo cache instead
 */
export const matrixRowReordered = questionnaireSlice.actions.matrixRowReordered

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionAdded =
  questionnaireSlice.actions.responseOptionAdded

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionCreated =
  questionnaireSlice.actions.responseOptionCreated

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionPasted =
  questionnaireSlice.actions.responseOptionPasted

/**
 * @deprecated Use the Apollo cache instead
 */
export const matrixRowPasted = questionnaireSlice.actions.matrixRowPasted

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionPinToggled =
  questionnaireSlice.actions.responseOptionPinToggled

/**
 * @deprecated Use the Apollo cache instead
 */
export const matrixRowPinToggled =
  questionnaireSlice.actions.matrixRowPinToggled

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionExclusiveToggled =
  questionnaireSlice.actions.responseOptionExclusiveToggled

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionTextEntryToggled =
  questionnaireSlice.actions.responseOptionTextEntryToggled

/**
 * @deprecated Use the Apollo cache instead
 */
export const maskingRulesSet = questionnaireSlice.actions.maskingRulesSet

/**
 * @deprecated Use the Apollo cache instead
 */
export const rowMaskingRulesSet = questionnaireSlice.actions.rowMaskingRulesSet

/**
 * @deprecated Use the Apollo cache instead
 */
export const rowForksSet = questionnaireSlice.actions.rowForksSet

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionRouteSet =
  questionnaireSlice.actions.responseOptionRouteSet

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionRouteUnset =
  questionnaireSlice.actions.responseOptionRouteUnset

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionImageSet =
  questionnaireSlice.actions.responseOptionImageSet

/**
 * @deprecated Use the Apollo cache instead
 */
export const responseOptionImageRemoved =
  questionnaireSlice.actions.responseOptionImageRemoved

/**
 * @deprecated Use the Apollo cache instead
 */
export const matrixRowImageSet = questionnaireSlice.actions.matrixRowImageSet

/**
 * @deprecated Use the Apollo cache instead
 */
export const matrixRowImageRemoved =
  questionnaireSlice.actions.matrixRowImageRemoved

/**
 * @deprecated Use the Apollo cache instead
 */
export const sliderNumbersSet = questionnaireSlice.actions.sliderNumbersSet

/**
 * @deprecated Use the Apollo cache instead
 */
export const setQuestionSetting = questionnaireSlice.actions.setQuestionSetting

/**
 * @deprecated Use the Apollo cache instead
 */
export const removeQuestionSetting =
  questionnaireSlice.actions.removeQuestionSetting

export default questionnaireSlice.reducer
