export enum EventType {
  SignedUp = 'signed up',
  Loggedin = 'logged in',
  CreatedNewProject = 'created new project',
  DuplicatedProject = 'duplicated project',
  OpenedAudienceBuilder = 'opened audience builder',
  AddedScreeningQuestion = 'added screening question',
  AddedTargetingCriteria = 'added targeting criterion',
  AddedSingleSelectQuestion = 'added single select question',
  AddedMultiSelectQuestion = 'added multi select question',
  AddedTextInputQuestion = 'added text input question',
  AddedRankedQuestion = 'added ranked question',
  AddedMatrixQuestion = 'added matrix question',
  AddedSliderQuestion = 'added slider question',
  AddedTextInstruction = 'added text instruction',
  AddedABLogicFork = 'added a/b logic fork',
  AddedMaxDiffQuestion = 'added max diff question',
  PreviewedSurvey = 'previewed survey',
  ExportedSurvey = 'exported survey',
  PurchasedProject = 'purchased project',
  FilteredByVariable = 'filtered by variable',
  AddedQuestionToFilters = 'added question to filters',
  ToggledResultsQuotas = 'toggled results quotas',
  DownloadedLongData = 'downloaded long data',
  DownloadedWideData = 'downloaded wide data',
  DownloadedSPSS = 'downloaded SPSS',
  DownloadedChartData = 'downloaded chart data',
  DownloadAsPptx = 'downloaded pptx',
  DownloadedFdChatResults = 'downloaded fdchat results',
  DeletedResponseOption = 'deleted response option',
  SelectedPresetAudience = 'selected preset audience',
  SelectedTargetingVariable = 'selected targeting variable',
  DeselectedTargetingVariable = 'deselected targeting variable',
  DeletedAllTargetingVariables = 'deleted all targeting variables',
  ViewedResultsPage = 'viewed results page',
  ToggledLogicOff = 'toggled logic off',
  ViewedSurveyPage = 'viewed survey page',
  ViewedConfirmPage = 'viewed confirm page',
  ViewedAudiencePage = 'viewed audience page',
  ClickedToYourProjectLaunch = 'clicked to your project - launch',
  DisabledQuotas = 'disabled quotas',
  EnabledQuotas = 'enabled quotas',
  ViewedAProject = 'viewed a project',
  SelectedCustomAudience = 'selected custom audience',
  ChangedAudiencePreset = 'changed audience preset',
  ClickedPricePreview = 'clicked price preview',
  ClosedSurveyPreviewPane = 'closed survey preview pane',
  OpenedSurveyPreviewPane = 'opened survey preview pane',
  AddedSkipLogic = 'added skip logic',
  AddedResponseDisplayLogic = 'added response display logic',
  AddedMatrixRowDisplayLogic = 'added matrix row display logic',
  ShowedLabelsOnSlider = 'showed labels on slider',
  HideTracker = 'hide tracker',
  DeletedQuestion = 'deleted question',
  DuplicatedQuestion = 'duplicated question',
  AddDisplayLogicToQuestion = 'add display logic to question',
  RandomisedOrder = 'randomised order',
  FixedOrder = 'fixed order',
  FlippedOrder = 'flipped order',
  TickedDefaultResponseOption = 'ticked default response option',
  SavedVideoMedia = 'saved video media',
  SavedImageMedia = 'saved image media',
  ToggledLogicOn = 'toggled logic on',
  ClickedShowAdvancedCriteria = 'clicked show advanced criteria',
  MadeResponseExclusive = 'made response exclusive',
  EnabledTextEntry = 'enabled text entry',
  EnabledNumericEntry = 'enabled numeric entry',
  RemoveTargetingFromSurvey = 'remove targeting from survey',
  AddTargetingToSurvey = 'add targeting to survey',
  AddChoiceResponseRange = 'added choice response range',
  InviteTeam = 'invited team',
  OpenedImportQuestions = 'opened import questions',
  ImportedFromSurvey = 'imported from survey',
  RequestedResetPassword = 'requested reset password',
  ResetPassword = 'reset password',
  ToggledSoftLaunch = 'toggled soft launch',
  ChangedSoftLaunchCompletes = 'changed soft launch completes',
  SelectedIncrementalFieldwork = 'selected incremental fieldwork',
  ChangedIncrementalFieldworkEndDate = 'changed incremental fieldwork end date',
  ClickedFullLaunch = 'clicked full launch',
  ConfirmedFullLaunch = 'confirmed full launch',
  TickedPostalCodeFull = 'ticked postal code full',
  RandomisedOrderForScreeningQuestion = 'randomised order for screening question',
  SetScreeningQuestionResponseOptionsLimit = 'set screening question response option limit',
  RemovedScreeningQuestionResponseOptionsLimit = 'removed screening question response option limit',
  EmptiedQuestionCard = 'emptied question card',
  AddedSection = 'added section',
  DeletedSection = 'deleted section',
  EnabledLooping = 'enabled looping',
  DisabledLooping = 'disabled looping',
  MovedCard = 'moved card',
  SectionQuestionsRandomisation = 'randomised section questions',
  ClickedCreateProject = 'clicked create project',
  // Events for the public results page
  ViewedPublicResults = 'viewed public results',
  ClickedFdLogoFromPublicResults = 'clicked fd logo from public results',
  ClickedContactSalesFromPublicResults = 'clicked contact sales from public results',
  ClickedRegisterFromPublicResults = 'clicked register from public results',
  DownloadChartDataFromPublicResults = 'downloaded chart data from public results',
  DownloadPptxFromPublicResults = 'download pptx from public results',
  ShowQuotasFromPublicResults = 'show quotas from public results',
  FilteredByVariableFromPublicResults = 'filtered by variable from public results',
  AddedQuestionToFiltersFromPublicResults = 'added question to filters from public results',
  WrongIdOrUnpublishedSurvey = 'accessed a survey that is unpublished or does not exist',
  OpenedInvalidQuestionnaireBadge = 'opened invalid questionnaire badge',
  ClickedQuestionLinkFromInvalidQuestionnaireBadge = 'clicked question link from invalid questionnaire badge',
  PausedProject = 'paused project',
  // FD Chat events
  ViewedFdChatObjectivesPage = 'viewed fd chat objectives page',
  ClickedPreviewFdChatInterview = 'clicked preview fd chat interview',
  ViewedFdChatAudiencePage = 'viewed fd chat audience page',
  ViewedFdChatReviewPage = 'viewed fd chat review page',
  ClickedLaunchFdChatProject = 'clicked launch fd chat project',
  ViewedFdChatFindingsPage = 'viewed fd chat findings page',
  ClickedViewFindings = 'clicked view findings',
  ViewedFdChatResearchQuestionPage = 'viewed fd chat research question page',
  ClickedViewTranscript = 'clicked view transcript',
  ViewedFdChatAskAiPage = 'viewed ask ai page',
  ClickedFdChatAskAiButton = 'clicked ask ai button',
  OpenedRequestFdChatFindingsDialog = 'opened request fd chat findings dialog',
  ClickedExportTranscripts = 'clicked export transcripts',
  ClickedFdChatAskAiExampleQuestion = 'clicked ask ai example question',
  ClickedFdChatAskAiSuggestedQuestion = 'clicked ask ai suggested question',
  ClickedFdChatAskAiSourcesCard = 'clicked ask ai sources card',
  ClickedFdChatAskAiCitation = 'clicked ask ai citation',
  ClickedFdChatAskAiRespondent = 'clicked ask ai respondent',
  ClickedFdChatAskAiCopyAnswer = 'clicked ask ai copy answer',
  ClickedFdChatSeeSampleReport = 'clicked see sample report',
  SubmittedFdChatAskAiAnswerFeedback = 'submitted ask ai answer feedback'
}
