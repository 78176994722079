import { Switch } from '@focaldata/cin-ui-components'
import React from 'react'
import { LogAmplitudeEvent } from '../../../amplitude'
import { EventType } from '../../../amplitude/eventType'
import { QuestionSettingCode } from '../../../data/model/questionnaire'
import useSetQuestionnaireSetting from '../../../hooks/questionnaire/useSetQuestionnaireSetting'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { getSettingValue } from '../../../utils/HelperFunctions'
import { WithEntry } from '../../../utils/questionnaireUtils'
import { BoolSettingToggler } from '../QuestionCardSwitches'
import { getEntryId } from '../Questionnaire.utils'

interface Props extends WithEntry {
  isSliderSwitchShowLabelsOn?: boolean
  onShowSliderLabels?: () => void
  onShowHideTracker?: () => void
  isSliderHideTrackerOn?: boolean
}

const SliderSwitches: React.FC<Props> = (props: Props) => {
  const {
    entry,
    isSliderSwitchShowLabelsOn,
    onShowSliderLabels,
    onShowHideTracker,
    isSliderHideTrackerOn
  } = props
  const surveyId = useSurveyId()
  const handleSettingChange = useSetQuestionnaireSetting(entry)
  const showLabelsText = 'Show labels'
  const hideTrackerText = 'Hide fill bar'

  const handleShowSliderLabels: BoolSettingToggler = (settingValue) => {
    if (onShowSliderLabels) {
      onShowSliderLabels()
    }
    handleSettingChange(
      [{ settingCode: QuestionSettingCode.SliderLabels, settingValue }],
      'no-cache'
    )
  }

  const handleShowHideTracker: BoolSettingToggler = (settingValue) => {
    if (onShowHideTracker) {
      onShowHideTracker()
    }
    handleSettingChange(
      [{ settingCode: QuestionSettingCode.HideTracker, settingValue }],
      'no-cache'
    )
  }

  return (
    <div className="fd-grid fd-container">
      <Switch
        first
        checked={isSliderSwitchShowLabelsOn}
        onChange={(event) => {
          const settingValue = getSettingValue(event.target.checked)
          handleShowSliderLabels(settingValue)

          if (event.target.checked) {
            LogAmplitudeEvent(EventType.ShowedLabelsOnSlider, {
              surveyId,
              questionId: getEntryId(entry)
            })
          }
        }}
        label={showLabelsText}
        id="show-labels-switch"
      />
      <Switch
        first
        checked={isSliderHideTrackerOn}
        onChange={(event) => {
          const settingValue = getSettingValue(event.target.checked)
          handleShowHideTracker(settingValue)

          if (event.target.checked) {
            LogAmplitudeEvent(EventType.HideTracker, {
              surveyId,
              questionId: getEntryId(entry)
            })
          }
        }}
        label={hideTrackerText}
        id="hide-tracker-switch"
      />
    </div>
  )
}

export default SliderSwitches
