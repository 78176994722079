import {
  IconButton,
  IconColor,
  IconName,
  IconSize,
  Text,
  TextHighlight,
  TextSize,
  TipPlacement,
  Tooltip
} from '@focaldata/cin-ui-components'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import React from 'react'
import useStyles from './TextEntryButton.styles'

interface Props {
  ariaLabel?: string
  onDisableTextEntry?: () => void
  isNumeric?: boolean
}

const TextEntryButton: React.FC<Props> = (props: Props) => {
  const { ariaLabel, onDisableTextEntry } = props
  const { classes } = useStyles()

  const entryType = props.isNumeric ? 'Numeric' : 'Text'

  const [textEntryAnchorEl, setTextEntryAnchorEl] =
    React.useState<null | HTMLElement>(null)
  const handleOpenTextEntryMenu: (
    event: React.MouseEvent<HTMLElement>
  ) => void = (event) => {
    setTextEntryAnchorEl(event.currentTarget)

    return false
  }
  const handleCloseTextEntryMenu: () => void = () => {
    setTextEntryAnchorEl(null)

    return false
  }

  const handleDisableTextEntry = () => {
    setTextEntryAnchorEl(null)
    onDisableTextEntry?.()
  }

  return (
    <Tooltip title={`${entryType} entry`} tipPlacement={TipPlacement.Top}>
      <>
        <IconButton
          ariaLabel={`${ariaLabel}`}
          iconName={
            props.isNumeric ? IconName.PinOutlined : IconName.TextFields
          }
          onClick={handleOpenTextEntryMenu}
          iconSize={IconSize.Large}
          className={classes.iconButton}
          iconColor={IconColor.Enabled}
        />
        <MuiMenu
          elevation={1}
          anchorEl={textEntryAnchorEl}
          open={Boolean(textEntryAnchorEl)}
          onClose={handleCloseTextEntryMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MuiMenuItem onClick={handleDisableTextEntry}>
            <Text size={TextSize.ms} highlight={TextHighlight.Background}>
              {`Disable ${entryType.toLowerCase()} entry`}
            </Text>
          </MuiMenuItem>
        </MuiMenu>
      </>
    </Tooltip>
  )
}

export default TextEntryButton
