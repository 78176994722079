import { makeStyles } from '@focaldata/cin-ui-components'
import { Theme } from '@mui/material'

const useStyles = makeStyles()((theme: Theme) => ({
  iconButton: {
    padding: theme.spacing(0.5)
  }
}))

export default useStyles
