import { useReactiveVar } from '@apollo/client'
import {
  Dialog,
  ExclusiveButton,
  IconColor,
  IconName,
  KebabMenu,
  KebabMenuIconPosition,
  KebabMenuOption,
  ListItemQuestionnaireResponseOption,
  RoutingButton,
  RoutingMenu,
  RoutingMenuItem,
  textStyleUtils
} from '@focaldata/cin-ui-components'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../App.store'
import { LogAmplitudeEvent } from '../../../../amplitude'
import { EventType } from '../../../../amplitude/eventType'
import { questionBeingEditedId } from '../../../../apollo/apolloClient'
import {
  QuestionDialogItem,
  QuestionsDialog
} from '../../../../components/QuestionsDialog'
import TextEntryButton from '../../../../components/TextEntry/TextEntryButton'
import {
  DraftEntryResponseOption,
  TextEntryState
} from '../../../../data/gql-gen/questionnaire/graphql'
import {
  DraftQuestionnaireEntry,
  EntryType,
  MediaType,
  PositionTextSelection,
  QuestionSettingCode,
  QuestionTypeCode
} from '../../../../data/model/questionnaire'
import useCopyPasteComplete, {
  onChangePasteAcceptingInput
} from '../../../../hooks/copyPaste/useCopyPasteComplete'
import useDraftQuestionnaireIdCache from '../../../../hooks/localState/useDraftQuestionnaireIdCache'
import { isNonEmptyResponseError } from '../../../../hooks/questionnaire/useQuestionnaireValidation'
import { useDebounceEffect } from '../../../../hooks/useDebounce'
import { useDisplayLogicDetector } from '../../../../hooks/useDisplayLogicDetector.hooks'
import { useSurveyId } from '../../../../hooks/useSurveyId'
import { isFocaldataUser } from '../../../../utils/authUtils'
import {
  checkIfMatrixMultipleChoice,
  checkIfMultipleChoice,
  isLastEntry,
  isSettingEnabled,
  newEntryId
} from '../../../../utils/questionnaireUtils'
import {
  MaskingButton,
  MaskingDialogContext
} from '../../Masking/MaskingButton'
import RowMediaUploader from '../../MediaUploader/MediaUploader'
import { PinResponse, PinnedItemType } from '../../PinResponse'
import { usePinResponseOption } from '../../PinResponse/PinResponse.hooks'
import {
  responseOptionExclusiveToggled,
  responseOptionImageRemoved,
  responseOptionImageSet,
  responseOptionTextEntryToggled,
  responseOptionUpdated,
  selectSettingsByQuestionId
} from '../../Questionnaire.slice'
import { useBasicEntryContext } from '../BasicQuestion.container'
import {
  useRemoveResponseOptionWithSettingsUpdate,
  useSetRouting,
  useUpdateResponseOption
} from './ResponseOptions.hooks'
import { ROUTE_TO_END_SURVEY_NUMBER } from './ResponseOptions.utils'

interface Props {
  entries: DraftQuestionnaireEntry[] | undefined
  routingText: string | undefined
  ariaLabel: string
  responseOption: DraftEntryResponseOption
  showEndOfSurveyRouting?: boolean
  shouldAutoFocus: boolean
  filteredRoutingMenuItems: RoutingMenuItem[] | undefined
  cannotDelete: boolean
  entryId: string
  routingDisabled: boolean
  maskingEnabled: boolean
  errorText: string
  onEnter: (position: number) => void
  onFocus: () => void
}

const ResponseOption: React.FC<Props> = (props: Props) => {
  const {
    responseOption,
    ariaLabel = 'Basic response option',
    shouldAutoFocus,
    entries: flatEntries,
    entryId,
    routingText,
    maskingEnabled,
    showEndOfSurveyRouting,
    filteredRoutingMenuItems,
    cannotDelete,
    routingDisabled,
    errorText,
    onEnter: createResponseOption,
    onFocus
  }: Props = props
  const surveyId = useSurveyId()
  const removeResponseOption =
    useRemoveResponseOptionWithSettingsUpdate(entryId)
  const updateResponseOption = useUpdateResponseOption(entryId)
  const pinResponseOption = usePinResponseOption(entryId)
  const setRouting = useSetRouting(entryId)

  const entryItem = useBasicEntryContext()
  const {
    responseOptionLk,
    position,
    exclusive,
    media,
    textEntryState,
    maskingRules,
    pinned,
    createdDate
  } = responseOption
  const value = responseOption.responseOption?.value ?? ''
  const settingValues =
    // @todo Legacy eslint violation – fix this when editing
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    useAppSelector((state) => selectSettingsByQuestionId(state, entryId)) || []
  // TODO: consider using it instead of MatrixResponseOption component, otherwise no need to use checkIfMatrixMultipleChoice
  const isMultiSelect =
    checkIfMultipleChoice(settingValues) ||
    checkIfMatrixMultipleChoice(settingValues)

  const [pendingDeletionPosition, setPendingDeletionPosition] = useState<
    number | undefined
  >(undefined)
  const [isRoutingPickerOpen, setIsRoutingPickerOpen] = useState(false)
  const [isMaskingDialogOpen, setIsMaskingDialogOpen] = useState(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [responseOptionValue, setResponseOptionValue] = useState<
    string | undefined
  >(responseOption.responseOption?.value)
  const [positionSelectedText, setPositionSelectedText] =
    useState<PositionTextSelection>({
      startPositionSelectedText: 0,
      endPositionSelectedText: 0
    })
  const newlyAddedEntryId = useReactiveVar(newEntryId)
  const [hasBeenFocused, setHasBeenFocused] = useState<boolean>(false)
  const [isImageDialogOpened, setIsImageDialogOpened] = useState(false)
  const dispatch = useAppDispatch()
  const disableRouting = isLastEntry(flatEntries, entryId)

  const isTextEntry = textEntryState === TextEntryState.TextEntryEnabled
  const isNumericEntry = textEntryState === TextEntryState.NumericEntryEnabled

  const { classes: textClasses, cx: classNames } =
    textStyleUtils.useTextStyles()

  const questionnaireId = useDraftQuestionnaireIdCache()

  const { pasteToResponseOption } = useCopyPasteComplete(entryId)

  const getQPrefixForQsWithDisplayLogicBasedOnResponse =
    useDisplayLogicDetector()

  const questionsPrefix = useMemo(
    () => getQPrefixForQsWithDisplayLogicBasedOnResponse(responseOptionLk),
    [responseOptionLk, getQPrefixForQsWithDisplayLogicBasedOnResponse]
  )

  const debounceDelayMs = 500

  const pasteInResponseOption = useCallback(
    async (
      text: string,
      position: number,
      positionSelectedText: PositionTextSelection
    ) => {
      questionBeingEditedId(entryId)
      await pasteToResponseOption(text, entryId, position, positionSelectedText)
    },
    [entryId, pasteToResponseOption]
  )

  const handleOnFocusedWithDelay: (delay?: number) => void = (
    delay = debounceDelayMs
  ) => {
    if (hasBeenFocused) return

    setTimeout(() => {
      setHasBeenFocused(true)
    }, delay)
  }

  const triggerResponseOptionChanged: () => void = () => {
    if (responseOptionValue !== undefined && responseOptionValue !== value) {
      dispatch(
        responseOptionUpdated({
          questionLk: entryId,
          responseOptionLk,
          value: responseOptionValue
        })
      )
      updateResponseOption({
        responseOptionId: responseOptionLk,
        responseOptionValue
      })
      handleOnFocusedWithDelay(100)
    }
  }

  useDebounceEffect<string | undefined>(
    () => {
      triggerResponseOptionChanged()
    },
    responseOptionValue,
    { delay: debounceDelayMs }
  )

  useEffect(() => {
    setResponseOptionValue(value)
  }, [value])

  const showRoutingBadge = routingText !== undefined

  const hasTempId = /^responseOptionLk-\d+$/.test(responseOptionLk)
  const isEditable = createdDate !== '' && !hasTempId

  const handleEnterResponseOption = () => {
    createResponseOption(position + 1)
  }

  if (position === pendingDeletionPosition) {
    return null
  }

  const isRandomiseOn = isSettingEnabled(
    settingValues,
    QuestionSettingCode.RandomiseOptions
  )

  const handleResponseOptionSelectedText = (
    selectionStart?: number | null,
    selectionEnd?: number | null
  ) => {
    setPositionSelectedText({
      startPositionSelectedText: selectionStart,
      endPositionSelectedText: selectionEnd
    })
  }

  const deleteResponse = async () => {
    setPendingDeletionPosition(position)
    await removeResponseOption(responseOptionLk)
    LogAmplitudeEvent(EventType.DeletedResponseOption, {
      surveyId,
      questionId: entryId
    })
  }

  const handleDelete = () => {
    if (questionsPrefix.length > 0) {
      setIsDeleteDialogOpen(true)
    } else {
      deleteResponse()
    }
  }

  const handleDeleteDialogConfirm = () => {
    setIsDeleteDialogOpen(false)
    deleteResponse()
  }

  const handleInputChange: (value: string) => void = (value) => {
    onChangePasteAcceptingInput(() => setResponseOptionValue(value))
  }
  const routingMenuItems = isRoutingPickerOpen
    ? filteredRoutingMenuItems
    : undefined

  const handlePinResponseOption = () => {
    pinResponseOption(responseOptionLk, true)
  }

  const setExclusive = (isExclusive: boolean) => {
    dispatch(
      responseOptionExclusiveToggled({
        questionLk: entryItem.questionLk,
        responseOptionLk,
        exclusive: isExclusive
      })
    )

    updateResponseOption({
      responseOptionId: responseOptionLk,
      exclusive: isExclusive
    })
  }

  const setTextEntryState = (textEntryState: TextEntryState) => {
    dispatch(
      responseOptionTextEntryToggled({
        questionLk: entryItem.questionLk,
        responseOptionLk,
        textEntryState
      })
    )

    updateResponseOption({
      responseOptionId: responseOptionLk,
      textEntryState
    })
  }

  const disableTextEntry = () => {
    setTextEntryState(TextEntryState.TextEntryDisabled)
  }

  const handleOpenMaskingDialog = () => {
    setIsMaskingDialogOpen(true)
  }
  const isMaxDiffQuestion =
    entryItem.questionTypeCode === QuestionTypeCode.MaxDiff

  const responseActions: KebabMenuOption[] = []

  if (!isMaxDiffQuestion) {
    responseActions.push({
      id: 2,
      textItem: 'Add display logic',
      disabled:
        // OR if this question is the first one and there are no other questions before it
        !maskingEnabled || maskingRules.length > 0,
      iconName: IconName.Visibility,
      onClickItem: handleOpenMaskingDialog
    })
    responseActions.push({
      id: 3,
      textItem: 'Pin response',
      disabled: pinned || !isRandomiseOn,
      iconName: IconName.PushPin,
      onClickItem: handlePinResponseOption
    })
    responseActions.push({
      id: 4,
      textItem: 'Add skip logic',
      disabled: showRoutingBadge || routingDisabled,
      iconName: IconName.TimelineOutlined,
      hasDivider: true,
      onClickItem: () => setIsRoutingPickerOpen(true)
    })
  }

  if (isMultiSelect) {
    responseActions.push({
      id: 5,
      textItem: 'Make exclusive',
      iconName: IconName.Filter1Outlined,
      disabled: exclusive ?? false,
      onClickItem: () => {
        setExclusive(true)

        LogAmplitudeEvent(EventType.MadeResponseExclusive, {
          surveyId,
          responseOptionValue: value
        })
      }
    })
  }

  if (entryItem.questionTypeCode === QuestionTypeCode.Basic) {
    responseActions.push({
      id: 6,
      textItem: 'Enable text entry',
      disabled: isTextEntry,
      iconName: IconName.TextFields,
      onClickItem: () => {
        setTextEntryState(TextEntryState.TextEntryEnabled)

        LogAmplitudeEvent(EventType.EnabledTextEntry, {
          surveyId,
          responseOptionValue: value
        })
      }
    })
    if (isFocaldataUser()) {
      responseActions.push({
        id: 7,
        textItem: 'Enable numeric entry',
        disabled: isNumericEntry,
        iconName: IconName.PinOutlined,
        onClickItem: () => {
          setTextEntryState(TextEntryState.NumericEntryEnabled)

          LogAmplitudeEvent(EventType.EnabledNumericEntry, {
            surveyId,
            responseOptionValue: value
          })
        }
      })
    }
  }

  const handleOpenAddFileDialog = () => {
    setIsImageDialogOpened(true)
  }

  if (
    entryItem.questionTypeCode === QuestionTypeCode.Basic ||
    entryItem.questionTypeCode === QuestionTypeCode.Ranked
  ) {
    responseActions.push({
      id: 8,
      textItem: 'Add image',
      iconName: IconName.Image,
      disabled: !!media,
      onClickItem: handleOpenAddFileDialog
    })
  }

  const lastItemInResponseActions = responseActions[responseActions.length - 1]

  // last item in menu should not have divider line
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (lastItemInResponseActions?.hasDivider) {
    lastItemInResponseActions.hasDivider = false
  }

  const handelExclusiveIconClick = isMultiSelect
    ? () => setExclusive(false)
    : undefined

  const handleMediaUpload = async (
    questionnaireId: string,
    questionLk: string,
    responseOptionLk: string,
    mediaUrl: string,
    mediaName: string,
    mediaType: MediaType,
    renderedMediaUrl: string
  ) => {
    dispatch(
      responseOptionImageSet({
        questionLk,
        responseOptionLk,
        mediaName,
        mediaUrl,
        renderedMediaUrl
      })
    )
    await updateResponseOption({
      responseOptionId: responseOptionLk,
      media: {
        value: {
          mediaUrl,
          mediaName,
          mediaType,
          renderedMediaUrl
        }
      }
    })
  }

  const handleRemoveMedia = async (
    questionLk: string,
    responseOptionLk: string
  ) => {
    dispatch(
      responseOptionImageRemoved({
        questionLk,
        responseOptionLk
      })
    )
    await updateResponseOption({
      responseOptionId: responseOptionLk,
      media: {
        value: null
      }
    })
  }

  const closeDeleteResponseDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  let deleteDialogText = ''
  if (questionsPrefix.length > 0) {
    deleteDialogText = `Deleting this response will also delete the display logic related to this question at ${questionsPrefix.join(
      ', '
    )}.`
  }
  const isNewlyAddedEntryId = newlyAddedEntryId === entryId
  const canShowError =
    !!errorText &&
    (hasBeenFocused ||
      !isNewlyAddedEntryId ||
      isNonEmptyResponseError(errorText))

  return (
    <>
      <ListItemQuestionnaireResponseOption
        responseOptionId={responseOptionLk}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={shouldAutoFocus}
        ariaLabel={ariaLabel}
        isError={canShowError}
        helperText={canShowError ? errorText : ''}
        cannotDelete={cannotDelete}
        onDelete={handleDelete}
        index={position}
        onEnter={handleEnterResponseOption}
        editable={isEditable}
        disableGutters
        draggableId={`dr${responseOptionLk}`}
        canBeReordered={!isMaxDiffQuestion}
        value={responseOptionValue || ''}
        placeholder="Type an option"
        onChange={handleInputChange}
        onSelect={handleResponseOptionSelectedText}
        mediaButton={
          <RowMediaUploader
            questionnaireId={questionnaireId}
            questionLk={entryId}
            responseOptionLk={responseOptionLk}
            mediaUrl={media?.mediaUrl}
            renderedMediaUrl={media?.renderedMediaUrl}
            mediaType={media?.mediaType}
            imageDialogOpened={isImageDialogOpened}
            onDialogClose={() => setIsImageDialogOpened(false)}
            onMediaUpload={handleMediaUpload}
            onRemoveMedia={handleRemoveMedia}
          />
        }
        maskingIconButton={
          <MaskingDialogContext.Provider
            value={{
              isDialogOpen: isMaskingDialogOpen,
              setIsDialogOpen: setIsMaskingDialogOpen
            }}
          >
            <MaskingButton
              questionLk={entryId}
              responseOptionLk={responseOptionLk}
              entryType={EntryType.QuestionEntryType}
            />
          </MaskingDialogContext.Provider>
        }
        pinningIconButton={
          pinned && isRandomiseOn ? (
            <PinResponse
              entryId={entryId}
              responseOptionLk={responseOptionLk}
              itemType={PinnedItemType.ResponseOption}
              isPinned={pinned}
            />
          ) : undefined
        }
        exclusiveButton={
          exclusive ? (
            <ExclusiveButton onExclusiveIconClick={handelExclusiveIconClick} />
          ) : undefined
        }
        textEntryButton={
          isTextEntry || isNumericEntry ? (
            <TextEntryButton
              onDisableTextEntry={disableTextEntry}
              isNumeric={isNumericEntry}
            />
          ) : undefined
        }
        routingButton={
          showRoutingBadge ? (
            <RoutingButton
              routingBadgeDisplayString={routingText}
              isRoutingDisabled={disableRouting}
              ariaLabel={ariaLabel}
              onRoutingMenuClick={() =>
                setIsRoutingPickerOpen(!isRoutingPickerOpen)
              }
            />
          ) : undefined
        }
        responseActionsMenu={
          responseActions.length > 0 ? (
            <KebabMenu
              kebabMenuOptions={responseActions}
              horizontal
              iconPosition={KebabMenuIconPosition.Left}
              iconColor={IconColor.Background}
              tooltipText="Configure this response option"
            />
          ) : undefined
        }
        questionsDialog={
          <QuestionsDialog
            title="Add skip logic"
            primaryButtonText="Add skip logic"
            endOfSurveyNumber={ROUTE_TO_END_SURVEY_NUMBER}
            showEndOfSurvey={showEndOfSurveyRouting}
            isQuestionDialogOpen={isRoutingPickerOpen && !showRoutingBadge}
            onQuestionDialogClose={() =>
              setIsRoutingPickerOpen(!isRoutingPickerOpen)
            }
            questionDialogItems={routingMenuItems as QuestionDialogItem[]}
            onSelectQuestionDialogItem={(targetNumber) => {
              setRouting(responseOptionLk, targetNumber)
            }}
          />
        }
        renderRoutingMenu={(inputRef?: React.RefObject<HTMLInputElement>) => (
          <RoutingMenu
            anchorEl={inputRef && inputRef.current}
            endOfSurveyNumber={ROUTE_TO_END_SURVEY_NUMBER}
            showEndOfSurvey={showEndOfSurveyRouting}
            hasRouting={showRoutingBadge}
            isRoutingMenuOpen={isRoutingPickerOpen && showRoutingBadge}
            onRoutingMenuClose={() => setIsRoutingPickerOpen(false)}
            routingMenuItems={routingMenuItems}
            onRoutingMenuItemClick={(targetNumber) => {
              setRouting(responseOptionLk, targetNumber)
            }}
          />
        )}
        onFocus={onFocus}
        onBlur={() => {
          handleOnFocusedWithDelay()
        }}
        onPaste={(text: string) => {
          pasteInResponseOption(text, position, positionSelectedText)
        }}
      />
      <Dialog
        title="Delete this response option?"
        open={isDeleteDialogOpen}
        onClose={closeDeleteResponseDialog}
        primaryButtonText="Delete response option"
        primaryButtonClick={handleDeleteDialogConfirm}
      >
        <p
          className={classNames(
            textClasses.default,
            textClasses.weightSemiBold
          )}
        >
          {deleteDialogText}
        </p>
      </Dialog>
    </>
  )
}

export default memo(ResponseOption)
