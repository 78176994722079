import dayjs from '../../../../utils/dayjs'

const DATE_FORMAT = 'D MMMM YYYY'

const adjustStartToTodayIfNeeded = (date: string | Date): Date => {
  const today = new Date()
  const isStartDateInThePast = dayjs(date).isBefore(new Date(), 'day')
  return isStartDateInThePast ? today : new Date(date)
}

export const getStartAndEndDatesAdjusted = (
  startDate: Date | string,
  deliveryDays: number
): {
  adjustedStartDate: string
  adjustedEndDate: string
} => {
  const adjustedStartDate = adjustStartToTodayIfNeeded(startDate)
  return {
    adjustedStartDate: adjustedStartDate.toISOString(),
    adjustedEndDate: dayjs(adjustedStartDate)
      .add(deliveryDays, 'day')
      .toISOString()
  }
}

export const getStartAndEndDatesFormatted = (
  startDate: Date | string,
  deliveryDays: number
): {
  formattedStartDate: string
  formattedEndDate: string
} => {
  const { adjustedStartDate, adjustedEndDate } = getStartAndEndDatesAdjusted(
    startDate,
    deliveryDays
  )
  return {
    formattedStartDate: dayjs(adjustedStartDate).format(DATE_FORMAT),
    formattedEndDate: dayjs(adjustedEndDate).format(DATE_FORMAT)
  }
}

export const getMaxDateBetweenDefaultAndEndDate = (
  isIncrementalFieldwork: boolean,
  formattedEndDate: string,
  fieldworkEndDate: Date | undefined
): string => {
  if (!isIncrementalFieldwork) {
    return formattedEndDate
  }
  return dayjs
    .max(dayjs(formattedEndDate, DATE_FORMAT), dayjs(fieldworkEndDate))
    .format(DATE_FORMAT)
}

export const getMaxEstimatedDeliveryDays = (
  isIncrementalFieldwork: boolean,
  deliveryDays: number,
  fieldworkEndDate: Date | undefined
): number => {
  if (!isIncrementalFieldwork) {
    return deliveryDays
  }
  const startOfFieldworkEndDate = dayjs(fieldworkEndDate).startOf('day')
  return startOfFieldworkEndDate.diff(dayjs().startOf('day'), 'day')
}
