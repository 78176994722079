import { useReactiveVar } from '@apollo/client'
import React, { memo, useState } from 'react'
import { useAppDispatch } from '../../../App.store'
import { LoggerErrorType } from '../../../data/logger'
import {
  DraftQuestionItem,
  QuestionSettingCode
} from '../../../data/model/questionnaire'
import { useDuplicateQuestionnaireEntry } from '../../../hooks/questionnaire/useDuplicateQuestionnaireEntry'
import useGetDraftQuestionnaire from '../../../hooks/questionnaire/useGetDraftQuestionnaire'
import useQuestionnaireValidation from '../../../hooks/questionnaire/useQuestionnaireValidation'
import { useUpdateQuestionnaireEntry } from '../../../hooks/questionnaire/useUpdateQuestionnaireEntry'
import SliderQuestionLayout from '../../../layouts/SliderQuestionLayout'
import {
  captureApolloError,
  getSettingBool
} from '../../../utils/HelperFunctions'
import { WithEntry, newEntryId } from '../../../utils/questionnaireUtils'
import { CardTitleContainer } from '../CardTitle'
import { sliderNumbersSet } from '../Questionnaire.slice'
import { flattenEntries } from '../Questionnaire.utils'
import { SurveyQuestionCardHeaderContainer } from '../SurveyQuestionCardHeader'
import DefaultOptions from './DefaultOptions'
import SliderLabelsControl from './SliderLabels'
import SliderQuestionControl from './SliderQuestion.control'
import SliderSwitches from './Switches'

interface Props extends WithEntry {
  shouldTitleInputFocus: boolean
}

const SliderQuestion: React.FC<Props> = (props: Props) => {
  const { entry, shouldTitleInputFocus } = props
  const entryItem = entry.entryItem as DraftQuestionItem
  const dispatch = useAppDispatch()

  const [isSliderSwitchShowLabelsOn, setIsSliderSwitchShowLabelsOn] =
    useState<boolean>(getSettingBool(entry, QuestionSettingCode.SliderLabels))

  const [isSliderHideTrackerOn, setIsSliderHideTrackerOn] = useState<boolean>(
    getSettingBool(entry, QuestionSettingCode.HideTracker)
  )

  const { validateBasicQuestionText, validateSliderNumbers } =
    useQuestionnaireValidation()

  const { draftQuestionnaireEntries } = useGetDraftQuestionnaire()
  const flatDraftQuestionnaireEntries = flattenEntries(
    draftQuestionnaireEntries
  )

  const { updateQuestionnaireEntry } = useUpdateQuestionnaireEntry(entry.id)

  const { duplicateQuestionnaireEntry } = useDuplicateQuestionnaireEntry(
    entry.id
  )

  const handleScaleNumberInputs = async (
    minNumber: number,
    maxNumber: number,
    stepNumber: number
  ) => {
    dispatch(
      sliderNumbersSet({
        questionLk: entryItem.questionLk,
        numbers: { min: minNumber, max: maxNumber, step: stepNumber }
      })
    )
    await updateQuestionnaireEntry(
      {
        updateBasicQuestion: {
          questionScale: {
            range: {
              max: maxNumber || 1,
              min: minNumber || 0,
              startingPosition: 0, // TODO: Change it once we allow different starting positions of the slider
              step: stepNumber || 0
            }
          }
        }
      },
      {
        onError: (error) => {
          captureApolloError(
            LoggerErrorType.ApolloMutation,
            'setQuestionScale',
            error
          )
        }
      }
    )
  }

  const handleScaleLabels = async (labelText: string, position: number) => {
    if (labelText || labelText === '') {
      const existingLabels = entryItem.questionScale?.labels ?? []
      const labelsInput = Array.from({ length: 3 }).map((_, i) => {
        const questionScaleLabel = existingLabels[i]
        if (!existingLabels[i]) {
          return {
            labelText: '',
            position: i
          }
        }

        if (position === i) {
          return {
            labelText,
            position
          }
        }

        return {
          labelText: questionScaleLabel.label.text,
          position: i
        }
      })
      labelsInput[position] = {
        labelText,
        position
      }
      await updateQuestionnaireEntry(
        {
          updateBasicQuestion: {
            questionScale: {
              labels: labelsInput
            }
          }
        },
        {
          onError: (error) => {
            captureApolloError(
              LoggerErrorType.ApolloMutation,
              'setQuestionScaleLabel',
              error
            )
          }
        }
      )
    }
  }

  const entryItemScaleQuestion = entryItem as DraftQuestionItem
  // @todo Legacy eslint violation – fix this when editing
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const emptyScaleQuestionTitle = entryItemScaleQuestion.question === null
  const isScaleQuestionTextEmpty = validateBasicQuestionText(
    entryItem.questionLk
  )
  const newlyCreatedEntryId = useReactiveVar(newEntryId)
  const isNewlyCreatedEntry =
    !!newlyCreatedEntryId && newlyCreatedEntryId === entryItem.questionLk

  const handleSwitchSlider = () => {
    setIsSliderSwitchShowLabelsOn(!isSliderSwitchShowLabelsOn)
  }

  const handlHideTracker = () => {
    setIsSliderHideTrackerOn(!isSliderHideTrackerOn)
  }

  return (
    <SliderQuestionLayout
      entryId={entry.id}
      questionCardHeader={
        <SurveyQuestionCardHeaderContainer
          entry={entry}
          hasError={!isNewlyCreatedEntry && isScaleQuestionTextEmpty}
          titleContent={
            <CardTitleContainer
              ariaLabel="Slider question header"
              questionLk={entryItem.questionLk}
              shouldTitleInputFocus={shouldTitleInputFocus}
              hasError={!isNewlyCreatedEntry && isScaleQuestionTextEmpty}
              shouldHandlePasteManually
            />
          }
          onClickDuplicateIcon={async () => {
            await duplicateQuestionnaireEntry({ position: entry.position + 1 })
          }}
          disabledDuplicate={emptyScaleQuestionTitle}
          entries={flatDraftQuestionnaireEntries}
        />
      }
      switches={
        <SliderSwitches
          entry={entry}
          onShowSliderLabels={handleSwitchSlider}
          isSliderSwitchShowLabelsOn={isSliderSwitchShowLabelsOn}
          onShowHideTracker={handlHideTracker}
          isSliderHideTrackerOn={isSliderHideTrackerOn}
        />
      }
      sliderInputs={
        <SliderQuestionControl
          entry={entry}
          onChangeNumbers={handleScaleNumberInputs}
          validationError={validateSliderNumbers(entryItem.questionLk)}
        />
      }
      sliderLabels={
        <SliderLabelsControl
          entry={entry}
          onChangeSliderLabels={handleScaleLabels}
        />
      }
      showLabels={isSliderSwitchShowLabelsOn}
      defaultOptions={<DefaultOptions entry={entry} />}
    />
  )
}

export default memo(SliderQuestion)
