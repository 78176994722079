import { convertAudienceType } from '../../../containers/Page/FdChat/AudiencePage/FdChatAudience'
import {
  useAddFdChatSpecification,
  useGetFdChatSpecification
} from '../../../containers/Page/FdChat/FdChat.hooks'
import {
  AdHocTestingSpecification,
  UpdateFieldworkV2Input
} from '../../../data/gql-gen/questionnaire/graphql'
import { draftAudienceRefetchQuery } from '../../../data/gql/questionnaire/queries/audiences'
import { FieldworkAudienceType } from '../../../data/model/fieldwork'
import { useUpdateFieldworkV2 } from '../../../hooks/audience/createOrUpdateFieldworkV2'
import { useAudienceCountries } from '../../../hooks/audience/useAudienceCountries'
import { useGetPresetAudiences } from '../../../hooks/audience/usePresetAudience'
import { useFieldwork } from '../../../hooks/useFieldwork'
import { useProjectId } from '../../../hooks/useProjectId'
import { useSurveyId } from '../../../hooks/useSurveyId'
import { reorderCountriesDropDown } from '../../../utils/HelperFunctions'
import { useProjectType } from '../../Project/Project.hooks'
import CountryPickerControl from './CountryPicker.control'
import CountryPickerLoading from './CountryPickerLoading'

const CountryPicker = ({
  setShowErrorPage
}: {
  setShowErrorPage?: (show: true) => void
}) => {
  const projectId = useProjectId()
  const surveyId = useSurveyId()
  const { isFdChat } = useProjectType()
  const { addFdChatSpecification } = useAddFdChatSpecification()
  const { fdChatSpecification } = useGetFdChatSpecification()

  const {
    data: dataCountries,
    loading: getCountriesLoading,
    error: getCountriesError
  } = useAudienceCountries()

  const { fieldwork, loading: getSupplierFieldworkLoading } = useFieldwork()

  const { updateFieldworkV2 } = useUpdateFieldworkV2()
  const { data: presetAudiences } = useGetPresetAudiences('GB', 'en')

  const handleChangeCountry = async (optionValue: string): Promise<void> => {
    const [newCountryCode, newLanguage] = optionValue.split('-')
    const newLocale = dataCountries?.find(
      ({ countryCode, locale }) =>
        countryCode === newCountryCode && locale.language === newLanguage
    )?.locale

    if (newLocale) {
      const audience = fieldwork?.audience[0]
      const panelSupplierCode = convertAudienceType(
        audience?.__typename as FieldworkAudienceType
      )

      const updateFieldworkInput: UpdateFieldworkV2Input = {
        surveyId,
        panelSupplierCode: panelSupplierCode,
        countryCode: newLocale.country,
        countryLanguage: newLocale.language
      }

      if (
        isFdChat &&
        newLocale.country === 'GB' &&
        newLocale.language === 'en'
      ) {
        const preset = presetAudiences?.find(
          (presetAudience) => presetAudience.name === 'Custom'
        )

        if (preset?.presetAudienceId) {
          updateFieldworkInput.presetAudienceId = preset.presetAudienceId
        }
      }

      await updateFieldworkV2({
        variables: {
          input: updateFieldworkInput
        },
        refetchQueries: [draftAudienceRefetchQuery(projectId, surveyId)]
      })

      if (isFdChat && fdChatSpecification) {
        const currentFdChatSpecification =
          fdChatSpecification as AdHocTestingSpecification

        addFdChatSpecification({
          variables: {
            input: {
              fdChatSpecification: {
                value: currentFdChatSpecification
              },
              projectId,
              surveyId
            }
          }
        })
      }
    }
  }

  const availableCountries = dataCountries ?? []

  const countries = reorderCountriesDropDown(availableCountries)
  const { country, language } = fieldwork?.locale || {
    country: 'GB',
    language: 'en'
  }
  const selectedCountryCode: string = `${country}-${language}` // If undefined default to United Kingdom

  if (getCountriesLoading || getSupplierFieldworkLoading) {
    return <CountryPickerLoading />
  }

  if (getCountriesError) {
    setShowErrorPage?.(true)
  }

  return (
    <CountryPickerControl
      defaultCountryCode={selectedCountryCode}
      countries={countries}
      onChangeSelectCountry={async (event) => {
        await handleChangeCountry(event.target.value)
      }}
    />
  )
}

export default CountryPicker
